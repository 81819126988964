import React from 'react';

import Story from './components/story';

export default function TopCashback() {
  const props = {
    name: 'TopCashback',
    title:
      'TopCashback is one of the UK’s largest cashback and rewards platforms, boasting a customer base of multi-millions coupled with over 4,600 merchants onboard. TopCashback is widely considered one of the most generous reward platforms in the UK.',
    seoTitle: 'How TopCashback grew their customer base with Fidel API',
    heroImgAlt:
      'A woman in a colourful blouse smiling and holding a smartphone',
    keyFigures: {
      industry: 'Cashback',
      hq: 'Stafford',
      founded: '2005',
      employees: '100+',
    },
    challengeElement: (
      <p>
        TopCashback needed a solution that would allow them to grow their
        customer base. The PCI requirements for the existing card-linked
        programme put a significant cost and burden on the business. TopCashback
        was looking for a way to focus on growing a card-linked customer base
        while increasing the number of in-store offers.
      </p>
    ),
    highlight:
      'TopCashback was able to triple the size of its card-linked customer base in just 3 months by integrating with Fidel API.',
    highlightImgAlt: 'A woman working on a laptop',
    solutionElement: (
      <div>
        <p>
          By working with Fidel API, TopCashback was able to set up a programme
          that integrated with their website in a matter of weeks. Fidel API was
          able to completely remove TopCashback’s PCI compliance burden by
          providing secure iframes for enrolling bank cards.
        </p>
        <p>
          Utilising Fidel API’s integrations with major card networks (Visa
          <sup>©</sup>, Mastercard<sup>©</sup> and American Express<sup>©</sup>
          ), TopCashback was able to triple the size of its card-linked customer
          base by allowing all major card types. Fidel API is now managing the
          platform, whilst TopCashback is enrolling thousands of customers per
          day and getting great value offers for in-store purchases.
        </p>
        <img
          src={getAsset('content_img2_topcashback').src}
          srcSet={getAsset('content_img2_topcashback').srcSet}
          alt="TopCashback’s website on a laptop screen"
          className="feature-image"
        />
      </div>
    ),
    benefits: [
      'Removing PCI Compliance burden',
      'Super fast integration process',
      'Excellent support',
    ],
  };

  return <Story {...props} />;
}

function getAsset(filename) {
  const src = require(`../../modules/stories/images/${filename}.png`).default;
  const src2x =
    require(`../../modules/stories/images/${filename}@2x.png`).default;
  const srcSet = `${src}, ${src2x} 2x`;

  return { src, srcSet };
}
